import logo from "../../assets/images/logo/logo.png";
import bodyImg from "../../assets/images/theme/image-3.png";

const About = () => {
  return (
    <div className="container-fluid" id="about">
      <div className="aboutbody">
        <div className="row bodydetails">
          <div className="titleHead">
            <h1>
              Empowering Individuals and Communities with Healthcare Tokens
            </h1>
          </div>

          <div className="detailsImg col-xl-6 col-md-12 col-sm-12 col-xs-12">
            <img className="dimg" src={bodyImg} alt="" />
          </div>
          <div className="details col-xl-6 col-md-12 col-sm-12 col-xs-12">
            <div className="aboutPara">
              <p>
                Our healthcare tokens provide access to essential medical
                services, ensuring that everyone can receive the care they need.
                As a non-profit project, we are committed to improving global
                healthcare outcomes.
              </p>
            </div>
            <div className="row mt-5">
              <div className="detailsabout">
                {/* <div className="imageIcon">
                  <img src={logo} alt="" />
                </div> */}
                <div className="smallbodyDetails mt-2">
                  <p>
                    Claim your free healthcare tokens and gain access to quality
                    medical services today.
                  </p>
                </div>
              </div>
              <div className="detailsabout">
                {/* <div className="imageIcon">
                  <img src={logo} alt="" />
                </div> */}
                <div className="smallbodyDetails mt-2">
                  <p>
                    Join us in revolutionizing healthcare by providing equitable
                    access to medical resources.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
