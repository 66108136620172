import { useState } from "react";
import { Link } from "react-scroll";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../../../assets/images/logo/logoTwo.png";
import { tokenDonate } from "../../blockchain/commonFunction";
import Swal from "sweetalert2";

const Header = () => {
  const [active, setActive] = useState(false);

  const donateToken = async () => {
    const donation = await tokenDonate();
    if (donation) {
      Swal.fire({
        icon: "success",
        title: "Donated Successfully",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
      });
    }
  };

  return (
    <div className="header-wrap">
      <div className="container-fluid">
        <div className="header">
          <nav className="navbar">
            <div className="logo">
              <img src={logo} className="imgLogo" />
            </div>
            <div className="navoption">
              <div className="btnDonate">
                <button onClick={() => donateToken()} className="donate">
                  Donate
                </button>
              </div>
              <div className="bar" onClick={() => setActive(!active)}>
                {active ? <FaTimes color="#fff" /> : <FaBars color="#fff" />}
              </div>
              <ul className={active ? "nav-menu active" : "nav-menu"}>
                {/* <li className="nav-item">
                  <Link
                    className="link"
                    to="home"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="link"
                    to="service"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                  >
                    Service
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="link"
                    to="project"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                  >
                    Projects
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link
                    className="link"
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                  >
                    About
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Header;
