import bodyImg from "../../assets/images/theme/healthcareworldwide.png";
import logo from "../../assets/images/logo/logo.png";
const Service = () => {
  return (
    <div className="container-fluid" id="service">
      <div className="servicebody">
        <div className="row bodydetails">
          <div className="titleHead">
            <h1>Providing Free Healthcare Tokens to People Worldwide</h1>
          </div>

          <div className="detailsImg col-xl-6 col-md-12 col-sm-12 col-xs-12">
            <img className="dimg" src={bodyImg} alt="" />
          </div>
          <div className="details col-xl-6 col-md-12 col-sm-12 col-xs-12">
            <div className="servicePara">
              <p>
                Our foundation is dedicated to improving global healthcare by
                offering free healthcare tokens to people around the world. With
                these tokens, individuals can access essential medical services
                and treatments.
              </p>
            </div>
            <div className="row mt-5">
              <div className="smallHeading mt-2">
                <p>Accessible Care</p>
              </div>
              <div className="smallbodyDetails mt-2">
                <p>
                  Claim your free healthcare tokens and gain access to quality
                  medical services today.
                </p>
              </div>
              <div className="smallHeading mt-2">
                <p>Empowering Change</p>
              </div>
              <div className="smallbodyDetails mt-2">
                <p>
                  Join us in revolutionizing healthcare by providing equitable
                  access to medical resources.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
