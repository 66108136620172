import bodyImg from "../../assets/images/theme/healthcaretokens.png";
import logo from "../../assets/images/logo/logo.png";

const Projects = () => {
  return (
    <div className="container-fluid" id="project">
      <div className="projectbody">
        <div className="row bodydetails">
          <div className="titleHead">
            <h1>Transforming Healthcare Through Data and Research</h1>
          </div>

          <div className="detailsImg col-xl-6 col-md-12 col-sm-12 col-xs-12">
            <img className="dimg" src={bodyImg} alt="" />
          </div>
          <div className="details col-xl-6 col-md-12 col-sm-12 col-xs-12">
            <div className="projectPara">
              <p>
                Discover the impact of our foundation's research and data-driven
                approach in improving global healthcare outcomes.
              </p>
            </div>
            <div className="row mt-5">
              <div className="col-xl-2 col-md-12 col-sm-12 col-xs-12">
                <div className="percentageIcon">
                  <h1>50%</h1>
                </div>
              </div>
              <div className="col-xl-10 col-md-12 col-sm-12 col-xs-12 smallbodyDetails">
                <p>
                  of the world's population lacks access to quality healthcare
                </p>
              </div>
              <div className="col-xl-2 col-md-12 col-sm-12 col-xs-12">
                <div className="percentageIcon">
                  <h1>50%</h1>
                </div>
              </div>
              <div className="col-xl-10 col-md-12 col-sm-12 col-xs-12">
                <div className="smallbodyDetails mt-2">
                  <p>
                    reduction in healthcare disparities through our initiatives.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
