import usdao from "../../assets/images/partners/usdao.svg";
import gfuture from "../../assets/images/partners/gfuture.svg";
import labs from "../../assets/images/partners/labs196.png";

const Partner = () => {
  return (
    <div className="container-fluid partnerContainer">
      <div className="PartnerTitle">
        <h1>Partners</h1>
      </div>
      <div className="row partner">
        <div className="pt-5 col-xl-4 col-md-4 col-sm-12 col-xs-12">
          <img className="imgPartner" src={usdao} alt="" />
        </div>
        <div className="pt-5 col-xl-4 col-md-4 col-sm-12 col-xs-12">
          <img className="imgPartner" src={gfuture} alt="" />
        </div>
        <div className="pt-5 col-xl-4 col-md-4 col-sm-12 col-xs-12">
          <img className="imgPartner" src={labs} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Partner;
