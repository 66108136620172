import Swal from "sweetalert2";
import Cookie from "universal-cookie";
import { getMetaMask } from "./commonFunction";

const cookie = new Cookie();
const expiresAt = 60 * 24;

const getConfirm = async (data) => {
  const provider = await getMetaMask();
  if (data.message) {
    return Swal.fire("Transaction declined!!");
  }
  let transac = await provider.waitForTransaction(data.transactionHash);
  // provider.waitForTransaction(data.transactionHash).then(async (res, err) => {
  let hash = await provider.waitForTransaction(transac.transactionHash);
  if (hash) {
    Swal.fire({
      icon: "success",
      title: "Congratulations you redeemd Token.",
    });
    return true;
  } else {
    Swal.fire({
      icon: "error",
      title: "There is some Issue.",
    });
    return false;
  }
  // });
};

const setCookies = (address) => {
  let date = new Date();
  const cookiesGet = getCookies();
  const claimedData = new Date(cookiesGet.date);
  date.setTime(date.getTime() + expiresAt * 60 * 1000);
  const futureDate = new Date(claimedData.getTime() + 24 * 60 * 60 * 1000);
  const option = { path: "/", expires: date };
  const val = { add: `${address}`, claim: `${true}`, date: `${new Date()}` };
  cookie.set("cookieClaim", JSON.stringify(val), option);
};

const getCookies = () => {
  let data = cookie.get("cookieClaim");
  if (data) return data;
  return false;
};

export { getConfirm, setCookies, getCookies };
