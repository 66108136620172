// import { useWeb3Modal } from "@web3modal/wagmi/react";
// import { useAccount } from "wagmi";
// import { useEffect } from "react";
import About from "./about";
import Faq from "./faq";
import Home from "./home";
import Partner from "./partner";
import Projects from "./project";
import Service from "./service";
import Social from "./socialMedia";
import Teams from "./team";

const Dashboard = () => {
  return (
    <div className="container-fluid dashboard-wrap">
      <div className="dashbody">
        <Home />
        <Service />
        <Projects />
        <About />
        <Faq />
        <Social />
        <Partner />
        <Teams />
      </div>
    </div>
  );
};

export default Dashboard;
