import React, { useState, useEffect } from "react";
import moment from "moment";
import { getCookies } from "../blockchain/helper";

const TimerCounter = () => {
  const [hr, setHr] = useState();
  const [minute, setMinute] = useState();
  const [second, setSecond] = useState();

  useEffect(() => {
    const cookiesGet = getCookies();
    const date = new Date();
    const claimedData = new Date(cookiesGet.date);
    const futureDate = new Date(claimedData.getTime() + 24 * 60 * 60 * 1000);
    const timeDifference = futureDate - date;
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const secondDifference = Math.floor((timeDifference % (1000 * 60)) / 1000);
    //checking the current date and claimed date
    if (!isNaN(claimedData.getTime())) {
      console.log("check");
      setHr(hoursDifference);
      setMinute(minutes);
      setSecond(secondDifference);
    } else {
      setHr("--");
      setMinute("--");
      setSecond("--");
    }
  }, [hr, minute, second]);

  return (
    <div className="timerBack">
      <p className="timer">{`${hr}:${minute}:${second}`}</p>
    </div>
  );
};

export default React.memo(TimerCounter);
