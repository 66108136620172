import teamImage from "../../assets/images/logo/image.png";
import { FaLinkedin, FaTwitter } from "react-icons/fa";

const Teams = () => {
  return (
    <div className="container-fluid">
      <div className="teamsPage">
        <div className="HeadingTeam">
          <h2>Meet Our Team</h2>
          <p>Passionate individuals dedicated to global healthcare</p>
        </div>
        <div className="scrollBehaviour">
          <div className="row teamBody">
            <div className="col-xl-4 col-md-4 col-sm-12 col-xs-12 teamsec">
              <div className="teamImg">
                <img src={teamImage} alt="" />
              </div>
              <div className="teamDetails">
                <p>John Deo</p>
                <p>CEO</p>
                <span className="mt-2">
                  <p>Bringing innovative solutions to healthcare challenges</p>
                </span>
                <div className="social">
                  <span>
                    <FaLinkedin className="icon" />
                  </span>
                  <span>
                    <FaTwitter className="icon" />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-12 col-xs-12 teamsec">
              <div className="teamImg">
                <img src={teamImage} alt="" />
              </div>
              <div className="teamDetails">
                <p>John Deo</p>
                <p>CEO</p>
                <span className="mt-2">
                  <p>Bringing innovative solutions to healthcare challenges</p>
                </span>
                <div className="social">
                  <span>
                    <FaLinkedin className="icon" />
                  </span>
                  <span>
                    <FaTwitter className="icon" />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-12 col-xs-12 teamsec">
              <div className="teamImg">
                <img src={teamImage} alt="" />
              </div>
              <div className="teamDetails">
                <p>John Deo</p>
                <p>CEO</p>
                <span className="mt-2">
                  <p>Bringing innovative solutions to healthcare challenges</p>
                </span>
                <div className="social">
                  <span>
                    <FaLinkedin className="icon" />
                  </span>
                  <span>
                    <FaTwitter className="icon" />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-12 col-xs-12 teamsec">
              <div className="teamImg">
                <img src={teamImage} alt="" />
              </div>
              <div className="teamDetails">
                <p>John Deo</p>
                <p>CEO</p>
                <span className="mt-2">
                  <p>Bringing innovative solutions to healthcare challenges</p>
                </span>
                <div className="social">
                  <span>
                    <FaLinkedin className="icon" />
                  </span>
                  <span>
                    <FaTwitter className="icon" />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-12 col-xs-12 teamsec">
              <div className="teamImg">
                <img src={teamImage} alt="" />
              </div>
              <div className="teamDetails">
                <p>John Deo</p>
                <p>CEO</p>
                <span className="mt-2">
                  <p>Bringing innovative solutions to healthcare challenges</p>
                </span>
                <div className="social">
                  <span>
                    <FaLinkedin className="icon" />
                  </span>
                  <span>
                    <FaTwitter className="icon" />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-12 col-xs-12 teamsec">
              <div className="teamImg">
                <img src={teamImage} alt="" />
              </div>
              <div className="teamDetails">
                <p>John Deo</p>
                <p>CEO</p>
                <span className="mt-2">
                  <p>Bringing innovative solutions to healthcare challenges</p>
                </span>
                <div className="social">
                  <span>
                    <FaLinkedin className="icon" />
                  </span>
                  <span>
                    <FaTwitter className="icon" />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-12 col-xs-12 teamsec">
              <div className="teamImg">
                <img src={teamImage} alt="" />
              </div>
              <div className="teamDetails">
                <p>John Deo</p>
                <p>CEO</p>
                <span className="mt-2">
                  <p>Bringing innovative solutions to healthcare challenges</p>
                </span>
                <div className="social">
                  <span>
                    <FaLinkedin className="icon" />
                  </span>
                  <span>
                    <FaTwitter className="icon" />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-12 col-xs-12 teamsec">
              <div className="teamImg">
                <img src={teamImage} alt="" />
              </div>
              <div className="teamDetails">
                <p>John Deo</p>
                <p>CEO</p>
                <span className="mt-2">
                  <p>Bringing innovative solutions to healthcare challenges</p>
                </span>
                <div className="social">
                  <span>
                    <FaLinkedin className="icon" />
                  </span>
                  <span>
                    <FaTwitter className="icon" />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-12 col-xs-12 teamsec">
              <div className="teamImg">
                <img src={teamImage} alt="" />
              </div>
              <div className="teamDetails">
                <p>John Deo</p>
                <p>CEO</p>
                <span className="mt-2">
                  <p>Bringing innovative solutions to healthcare challenges</p>
                </span>
                <div className="social">
                  <span>
                    <FaLinkedin className="icon" />
                  </span>
                  <span>
                    <FaTwitter className="icon" />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-12 col-xs-12 teamsec">
              <div className="teamImg">
                <img src={teamImage} alt="" />
              </div>
              <div className="teamDetails">
                <p>John Deo</p>
                <p>CEO</p>
                <span className="mt-2">
                  <p>Bringing innovative solutions to healthcare challenges</p>
                </span>
                <div className="social">
                  <span>
                    <FaLinkedin className="icon" />
                  </span>
                  <span>
                    <FaTwitter className="icon" />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 col-sm-12 col-xs-12 teamsec">
              <div className="teamImg">
                <img src={teamImage} alt="" />
              </div>
              <div className="teamDetails">
                <p>John Deo</p>
                <p>CEO</p>
                <span className="mt-2">
                  <p>Bringing innovative solutions to healthcare challenges</p>
                </span>
                <div className="social">
                  <span>
                    <FaLinkedin className="icon" />
                  </span>
                  <span>
                    <FaTwitter className="icon" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Teams;
