import { FaTelegramPlane } from "react-icons/fa";
import { BiLogoDiscord } from "react-icons/bi";
import { IoLogoGithub } from "react-icons/io";
import { FaInstagramSquare } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaMedium } from "react-icons/fa6";

const Social = () => {
  return (
    <div className="mediaBody">
      <div className="mediaTitle">
        <h1>Social Links</h1>
      </div>
      <div className="container">
        <div className="row mt-5">
          <div className="mediaImg col-xs-6 col-sm-6 col-md-4 col-xl-3 mt-3">
            <div className="mediaBox">
              <div className="socialMedia">
                <FaTelegramPlane className="linkLogo" />
                <span className="linkText">
                  <p>Telegram</p>
                </span>
              </div>
            </div>
          </div>
          <div className="mediaImg col-xs-6 col-sm-6 col-md-4 col-xl-3 mt-3">
            <div className="mediaBox">
              <div className="socialMedia">
                <BiLogoDiscord className="linkLogo" />
                <span className="linkText">
                  <p>Discord</p>
                </span>
              </div>
            </div>
          </div>
          <div className="mediaImg col-xs-6 col-sm-6 col-md-4 col-xl-3 mt-3">
            <div className="mediaBox">
              <div className="socialMedia">
                <IoLogoGithub className="linkLogo" />
                <span className="linkText">
                  <p>Github</p>
                </span>
              </div>
            </div>
          </div>
          <div className=" mediaImg col-xs-6 col-sm-6 col-md-4 col-xl-3 mt-3">
            <div className="mediaBox">
              <div
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/cure2050official?igsh=eDlrZ3dvYTNnc2ps"
                  )
                }
                className="socialMedia"
              >
                <FaInstagramSquare className="linkLogo" />
                <span className="linkText">
                  <p>Instagram</p>
                </span>
              </div>
            </div>
          </div>
          <div className="mediaImg col-xs-6 col-sm-6 col-md-4 col-xl-3 mt-3">
            <div className="mediaBox">
              <div
                onClick={() => window.open("https://twitter.com/cure2050")}
                className="socialMedia"
              >
                <FaTwitter className="linkLogo" />
                <span className="linkText">
                  <p>Twitter</p>
                </span>
              </div>
            </div>
          </div>
          <div className="mediaImg col-xs-6 col-sm-6 col-md-4 col-xl-3 mt-3">
            <div className="mediaBox">
              <div className="socialMedia">
                <FaFacebookF className="linkLogo" />
                <span className="linkText">
                  <p>Facebook</p>
                </span>
              </div>
            </div>
          </div>
          <div className="mediaImg col-xs-6 col-sm-6 col-md-4 col-xl-3 mt-3">
            <div className="mediaBox">
              <div className="socialMedia">
                <FaLinkedinIn className="linkLogo" />
                <span className="linkText">
                  <p>Linkedin</p>
                </span>
              </div>
            </div>
          </div>
          <div className="mediaImg col-xs-6 col-sm-6 col-md-4 col-xl-3 mt-3">
            <div className="mediaBox">
              <div className="socialMedia">
                <FaMedium className="linkLogo" />
                <span className="linkText">
                  <p>Medium</p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Social;
