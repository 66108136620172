import { MdOutlineMailOutline } from "react-icons/md";
import { Link } from "react-scroll";
import { BiMessageDetail } from "react-icons/bi";
import { LuPhone } from "react-icons/lu";
import { IoLocationOutline } from "react-icons/io5";
import logo from "../../../assets/images/logo/logoTwo.png";

const Footer = () => {
  return (
    <div className="container-fluid" id="contact">
      <div className="footerBody">
        {/* <div className="footerHeading">
          <h1>Get in Touch</h1>
          <p>For inquiries or support, please contact us.</p>
        </div> */}
        {/* <div className="row footerBodyLogo">
          <div className="col-xl-3 col-md-6 col-sm-12 col-xs-12">
            <div className="contactLogo">
              <MdOutlineMailOutline className="LogoDetails" />
            </div>
            <div className="contactTextDetails">
              <h3>Email</h3>
              <p>info@foundation.org</p>
              <p>hello@foundation.org</p>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-12 col-xs-12">
            <div className="contactLogo">
              <BiMessageDetail className="LogoDetails" />
            </div>
            <div className="contactTextDetails">
              <h3>Live Chat</h3>
              <p>Chat with our support Team</p>
              <p>Start Chat</p>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-12 col-xs-12">
            <div className="contactLogo">
              <LuPhone className="LogoDetails" />
            </div>
            <div className="contactTextDetails">
              <h3>Phone</h3>
              <p>info@foundation.org</p>
              <p>hello@foundation.org</p>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-12 col-xs-12">
            <div className="contactLogo">
              <IoLocationOutline className="LogoDetails" />
            </div>
            <div className="contactTextDetails">
              <h3>Office</h3>
              <p>123, Main st, Antown, USA</p>
              <p>123, Main st, Sydney</p>
            </div>
          </div>
        </div> */}
        <div className="otherDetails">
          <div className="detailLogo  ">
            <img src={logo} alt="" />
          </div>
          <ul className="detailsOther ">
            <li>
              <Link
                className="link"
                to="about"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                className="link"
                to="service"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Service
              </Link>
            </li>
            <li>
              <Link
                className="link"
                to="contact"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                Contact Us
              </Link>
            </li>
            <li>
              <Link
                className="link"
                to="faq"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                FAQs
              </Link>
            </li>
            <li>Blog</li>
          </ul>
        </div>
        <div className="endSection">
          <hr />
        </div>
      </div>
    </div>
  );
};

export default Footer;
