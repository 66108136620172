import { ethers } from "ethers";
import Web3 from "web3";
import { getAccount } from "@wagmi/core";
import { contractDetails } from "./contractDetails";
import moment from "moment";

const getMetaMask = () => {
  const providerMetaMask = new ethers.providers.Web3Provider(window.ethereum);
  return providerMetaMask;
};

const TotalClaim = "10000000000000000000000";
const getChainId = async () => {
  const chainId = await window.ethereum.request({ method: "net_version" });
  return chainId;
};

const writeContractFunction = async (token) => {
  try {
    const chainId = await getChainId();
    let web3 = new Web3(window.ethereum);
    return new web3.eth.Contract(
      contractDetails[token].abi,
      contractDetails[token].address[chainId]
    );
  } catch (e) {
    console.log(e);
  }
};

const readContractFunction = async (token) => {
  try {
    const chainId = await getChainId();
    let web3 = new Web3(window.ethereum);
    return new web3.eth.Contract(
      contractDetails[token].abi,
      contractDetails[token].address[chainId]
    );
  } catch (e) {
    console.log(e);
  }
};

const checkClaimedAmount = async () => {
  try {
    const { address } = getAccount();
    const tokenval = await readContractFunction("token");
    const redeem = await tokenval.methods.claimedAmounts(address).call();
    if (Number(redeem) < Number(TotalClaim)) {
      return false;
    }
    return true;
  } catch (err) {
    console.log(err);
  }
};

const getToken = async () => {
  try {
    const { address } = getAccount();
    const tokenval = await writeContractFunction("token");
    const redeem = await tokenval.methods
      .claimAirdrop()
      .send({ from: address });
    if (redeem) {
      return redeem;
    }
  } catch (err) {
    return err;
  }
};

const getRmainingToken = async () => {
  try {
    const tokenval = await readContractFunction("token");
    const check = await tokenval.methods.totalClaimedin24().call();
    return check;
  } catch (e) {
    console.log(e);
  }
};
const getNumberOfHolders = async () => {
  try {
    const tokenval = await readContractFunction("token");
    const check = await tokenval.methods.getTokenHolders().call();
    return check;
  } catch (e) {
    console.log(e);
  }
};
const getRemainingToken = async () => {
  try {
    const tokenval = await readContractFunction("token");
    const check = await tokenval.methods.remainingAirdropTokens().call();
    return check;
  } catch (e) {
    console.log(e);
  }
};

const tokenDonate = async () => {
  try {
    const { address } = getAccount();
    const tokenval = await readContractFunction("token");
    const check = await tokenval.methods.balanceOf(address).call();
    const tokenWrite = await writeContractFunction("token");
    const donate = await tokenWrite.methods
      .donateToReliefPool(check)
      .send({ from: address });
    return donate;
  } catch (e) {
    console.log(e);
  }
};

const getAirDrop = async () => {
  try {
    const date = new Date();
    const getProvider = await readContractFunction("token");
    const getRemainingTime = await getProvider.methods.airdropPeriod().call();
    const getTimeDifference =
      Math.floor(getRemainingTime * 1000000) - date.getTime();
    const remainigDay = Math.floor(getTimeDifference / 86400000000);
    return remainigDay;
  } catch (e) {
    console.log(e);
  }
};

export {
  getToken,
  checkClaimedAmount,
  getMetaMask,
  getRmainingToken,
  getNumberOfHolders,
  getRemainingToken,
  tokenDonate,
  getAirDrop,
};
