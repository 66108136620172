import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import logo from "../../assets/images/logo/logoTwo.png";
import {
  checkClaimedAmount,
  getAirDrop,
  getNumberOfHolders,
  getRemainingToken,
  getRmainingToken,
  getToken,
} from "../blockchain/commonFunction";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import Swal from "sweetalert2";
import ReactLoading from "react-loading";
import { getConfirm, getCookies, setCookies } from "../blockchain/helper";
import { ethers } from "ethers";
import TimerCounter from "./timerCount";

const Home = () => {
  const [enable, setEnable] = useState(false);
  const { open } = useWeb3Modal();
  const { address } = useAccount();
  const [urlCopy, setUrlCopy] = useState("");
  const [confirmPop, setConfirmPop] = useState(false);
  const [claimBtn, setClaimBtn] = useState("Claim Token");
  const [alreadyClaimed, setAlreadyClaimed] = useState(false);
  const [tokenNumber, setTokenNumber] = useState(0);
  const [load, setLoad] = useState(false);
  const [totalClaimed, setTotalClaimed] = useState();
  const [numberOfHolder, setNumberOfHolder] = useState();
  const [remainingAirdrop, setRemainingAirdrop] = useState();
  const [time, setTime] = useState();
  const [hr, setHr] = useState();
  const [minute, setMinute] = useState();
  const [second, setSecond] = useState();
  const [day, setDay] = useState();

  if (window.ethereum) {
    window.ethereum.on("accountsChanged", async (_) => {
      setEnable(true);
    });
    window.ethereum.on("chainChanged", (_) => window.location.reload());
  }

  useEffect(() => {
    (async () => {
      const total = await getRmainingToken();
      setTotalClaimed(ethers.utils.formatEther(total));

      const noHolder = await getNumberOfHolders();
      setNumberOfHolder(noHolder);

      const remain = await getRemainingToken();
      setRemainingAirdrop(ethers.utils.formatEther(remain));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const remainingDay = await getAirDrop();
      setDay(remainingDay);
    })();
  }, []);

  const tags = [
    {
      value: numberOfHolder,
      subtitle: "No of Holders",
    },
    {
      value: remainingAirdrop,
      subtitle: "Remainig Token",
    },
    {
      value: totalClaimed,
      subtitle: "Total Claimed",
    },
    {
      value: (
        <div>
          <TimerCounter />
        </div>
      ),
      subtitle: "Remaining Time",
    },
    {
      value: day,
      subtitle: "Airdrop Remaining Time",
    },
  ];

  const toakenRedeem = () => {
    try {
      getToken(tokenNumber).then((res) => {
        if (!res.code) {
          console.log("acc", res.code);
          // setOpen(false);
          setConfirmPop(true);
        }
        if (res.code) {
          setTimeout(() => {
            setClaimBtn("Claim Token");
          }, 3000);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  // useEffect(() => {
  //   const cookiesGet = getCookies();
  //   const date = new Date();
  //   const claimedData = new Date(cookiesGet.date);
  //   const futureDate = new Date(claimedData.getTime() + 24 * 60 * 60 * 1000);
  //   const timeDifference = futureDate - date;
  //   const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
  //   const minutes = Math.floor(
  //     (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
  //   );
  //   const secondDifference = Math.floor((timeDifference % (1000 * 60)) / 1000);
  //   // console.log(
  //   //   "Hours",
  //   //   hoursDifference,
  //   //   "minutes",
  //   //   minutes,
  //   //   "second",
  //   //   secondDifference
  //   // );
  //   // setInterval(() => {
  //   setTime(moment(timeDifference).format("hh:mm:ss"));
  //   // setHr(hoursDifference);
  //   // setMinute(minutes);
  //   // setSecond(secondDifference);
  //   // }, 1000);

  //   console.log("Interval===> ", moment(timeDifference).format("hh:mm:ss"));
  // }, []);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const checkClaim = await checkClaimedAmount();
  //       if (checkClaim) {
  //         setClaimBtn("Claimed");
  //         setAlreadyClaimed(true);
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   })();
  // }, []);

  const sliderLeft = () => {
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft - 500;
  };
  const sliderRight = () => {
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  const handleSubmit = async () => {
    try {
      if (!address) {
        open();
      } else {
        const cookiesGet = getCookies();
        if (urlCopy.includes("status") && urlCopy.includes("s")) {
          setLoad(true);
          if (cookiesGet.add !== address) {
            let claim = await getToken();
            const Confirm = await getConfirm(claim);
            if (Confirm) {
              setLoad(false);
              setUrlCopy("");
              setCookies(address);
              return null;
            }
            Swal.fire({
              icon: "warning",
              title: "Some Issue with Transaction",
            });
            setLoad(false);
            return null;
          }
          Swal.fire({
            icon: "warning",
            title: "You already Claimed. Please claim after 24 hours",
          });
          setLoad(false);
        } else {
          Swal.fire({ icon: "error", title: "Please provide correct URL" });
          setLoad(false);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleShare = () => {
    try {
      if (address) {
        const tweetText = `Hey I just got free HealthCare tokens 🔥 Claim Yours at cure2050.com\n\nWallet address -${address}`;
        const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
          tweetText
        )}`;
        window.open(tweetUrl);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {load && (
        <div className="d-flex justify-content-center">
          <ReactLoading
            type={"spin"}
            color={"#fff"}
            height={"10%"}
            width={"10%"}
          />
        </div>
      )}
      {!load && (
        <div className="container-fluid home" id="home">
          <div className="dashboard-logo">
            <img src={logo} alt="" />
          </div>
          <div className="row">
            <div className="col-xl-12 col-md-12 col-sm-12 col-xs-12 homeTitle">
              <div className="homeInput">
                <input
                  value={urlCopy}
                  onChange={(e) => setUrlCopy(e.target.value)}
                  className="inputfield"
                  type="text"
                  placeholder="Paste Your Link"
                />
              </div>
              {/* </div>
        <div className="col-xl-6 col-md-6 col-sm-12 col-xs-12 paragraph">
          <div className="para">
            <p>
              Join our global healthcare project and claim your free healthcare
              tokens today.
            </p>
          </div> */}
              {/* <div className="claim">
            <button
              disabled={confirmPop ? true : false}
              className="claimbtn"
              onClick={() => setOpen(!open)}
            >
              Claim
            </button>
            <button className="learnbtn">Learn more</button>
          </div> */}
              {/* {!enable && (
          )} */}
              <div className="claim">
                <OverlayTrigger
                  overlay={
                    <Tooltip>
                      Share a Post about us and Copy URL and submit to get Token
                    </Tooltip>
                  }
                >
                  <button onClick={() => handleShare()} className="sharebtn">
                    Share
                  </button>
                </OverlayTrigger>

                <button
                  // disabled={address === undefined ? true : false}
                  className="claimbtn"
                  onClick={() => handleSubmit()}
                >
                  {address ? "Claim" : "Connect"}
                </button>
              </div>

              {/* {enable && (
            <div className="claim">
              <button className="learnbtn">Share</button><button
                disabled={confirmPop ? true : false}
                className="claimbtn"
                onClick={() => handleSubmit()}
              >
                Claim
              </button>
            </div>
          )} */}
              {/* <button className="claimbtn" onClick={() => redeemToken()}> after claim */}
            </div>
          </div>
          <div className="Imgsection">
            <MdChevronLeft
              onClick={() => sliderLeft()}
              className="arrowsec"
              size={40}
            />
            <div id="slider" className="sliderSection">
              {tags.map((item, index) => (
                <div key={index} className="sliderCard">
                  <h2>
                    {item.value}
                    {/* {item.value > 8 ? "M" : ""} */}
                  </h2>
                  <p>{item.subtitle}</p>
                </div>
              ))}
            </div>
            <MdChevronRight
              onClick={() => sliderRight()}
              className="arrowsec"
              size={40}
            />
          </div>

          {/* {open ? (
        <Modal
          redeem={() => toakenRedeem()}
          onClose={() => setOpen(false)}
          alreadyClaimed={alreadyClaimed}
          claimButton={claimBtn}
          setTokenNumber={setTokenNumber}
        />
      ) : null}
      {confirmPop ? (
        <ConfirmModal onClose={() => setConfirmPop(false)} />
      ) : null} */}
        </div>
      )}
    </>
  );
};

export default Home;
