import logo from "../../assets/images/logo/plus.png";
import image from "../../assets/images/theme/image-3.png";
import react, { useState } from "react";

const Faq = () => {
  const faqDec = [
    {
      id: 1,
      question: "How to claim tokens?",
      answer:
        "To claim tokens, visit our website and follow the instructions provided. You will need to create an account and complete the verification process.",
    },
    {
      id: 2,
      question: "What is the token allocation?",
      answer:
        "To claim tokens, visit our website and follow the instructions provided. You will need to create an account and complete the verification process.",
    },
    {
      id: 3,
      question: "How does it work?",
      answer:
        "The healthcare token can be used to access healthcare services and products within our network. It provides a secure and transparent way to facilitate transactions and ensure the availability of healthcare for those in need.",
    },
    {
      id: 4,
      question: "What is the foundation?",
      answer:
        "The foundation is a non-profit organization dedicated to improving healthcare access for people all around the world. It works towards providing free healthcare services and support to those in need.",
    },

    {
      id: 5,
      question: "Why choose us?",
      answer:
        "We are committed to making a positive impact on global healthcare by leveraging blockchain technology. Our transparent and decentralized system ensures that healthcare services reach the right people at the right time.",
    },
  ];

  const [showAnswer, setShowAnswer] = useState();
  const [show, setShow] = useState(false);

  const handleAnswer = (item) => {
    if (item.id) {
      setShowAnswer(item.id);
      setShow(!show);
    }
  };

  return (
    <div className="container-fluid" id="faq">
      <div className="faqBody">
        <div className="faqHeadTitle">
          <h1>FAQs</h1>
          <p>
            Find answers to common questions about the healthcare token and its
            claiming process.
          </p>
        </div>
        <div className="row faqBodyDsc mt-5">
          <div className="col-xl-6 col-md-6 col-sm-12 col-xs-12">
            <img src={image} className="faqimg" alt="" />
          </div>
          <div className="col-xl-6 col-md-6 col-sm-12 col-xs-12">
            {faqDec.map((item, index) => (
              <div className="faqHead" key={item.id}>
                <div className="faqQuestion">
                  <p>{item.question}</p>
                  <img
                    className="pointerimg"
                    onClick={() => handleAnswer(item)}
                    src={logo}
                    alt=""
                  />
                </div>
                {showAnswer === item.id && show !== false ? (
                  <div className="faqAnswer">
                    <p>{item.answer}</p>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </div>
        {/* <div className="row faqBodyDsc mt-2">
          <div className="faqHead col-xl-6 col-md-6 col-sm-12 col-xs-12">
            <p>What is the token allocation?</p>
          </div>
          <div className="col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
            <p>
              Out of the total 21 billion tokens, 1 billion tokens have been
              allocated to the foundation, while the remaining 20 billion tokens
              are available for claim from our website within 364 days.
            </p>
          </div>
          <hr />
        </div>
        <div className="row faqBodyDsc mt-2">
          <div className="faqHead col-xl-6 col-md-6 col-sm-12 col-xs-12">
            <p>How does it work?</p>
          </div>
          <div className="col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
            <p>
              The healthcare token can be used to access healthcare services and
              products within our network. It provides a secure and transparent
              way to facilitate transactions and ensure the availability of
              healthcare for those in need.
            </p>
          </div>
          <hr />
        </div>
        <div className="row faqBodyDsc mt-2">
          <div className="faqHead col-xl-6 col-md-6 col-sm-12 col-xs-12">
            <p>What is the foundation?</p>
          </div>
          <div className="col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
            <p>
              The foundation is a non-profit organization dedicated to improving
              healthcare access for people all around the world. It works
              towards providing free healthcare services and support to those in
              need.
            </p>
          </div>
          <hr />
        </div>
        <div className="row faqBodyDsc mt-2">
          <div className="faqHead col-xl-6 col-md-6 col-sm-12 col-xs-12">
            <p>Why choose us?</p>
          </div>
          <div className="col-xl-6 col-md-6 col-sm-12 col-xs-12 mb-4">
            <p>
              We are committed to making a positive impact on global healthcare
              by leveraging blockchain technology. Our transparent and
              decentralized system ensures that healthcare services reach the
              right people at the right time.
            </p>
          </div>
          <hr />
        </div> */}
      </div>
    </div>
  );
};

export default Faq;
